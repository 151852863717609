<template>
  <div class="blu-box">
    <div class="blu-info-box">
      <van-badge @click="onBluetooth" :content="content" :color="color" :style="`border: 2px solid ${color} ;   border-radius: 5px;`">
        <div class="child" >
          <img :src="imgBlu" alt="">
          <span>{{BluetoothName==""?"点击连接":BluetoothName}}</span>
        </div>
      </van-badge>
    </div>
    <div class="blu-text-box">
      <div class="blu-info-item-box">
        <div :class="`blu-info-text-box-item bor ${BluetoothStatus?'':'disabled-element'}`">
          <div class="blu-info-text-box-item-box" @click="onSetWifi"><van-icon size="1rem" name="setting-o" /><span>wifi设置</span></div>
        </div>
        <div :class="`blu-info-text-box-item ${BluetoothStatus?'':'disabled-element'}`">
          <div class="blu-info-text-box-item-box" @click="onSetBlu"><van-icon size="1rem" name="edit" /><span>蓝牙名称设置</span></div>
        </div>
      </div>
      <div class="blu-info-text-box">
        <div class="blu-info-text-box-title">
          设备信息
        </div> 
        <van-skeleton title :row="3" :loading="loading">
          <van-cell-group v-show="facilityInfo.facilityIP!==''" inset>
            <van-field label="设备IP"  left-icon="star"  v-model="facilityInfo.facilityIP" readonly />
            <van-field label="设备服务"  v-model="facilityInfo.facilityService" readonly />
            <van-field label="设备特征"  v-model="facilityInfo.facilityTrait" readonly />
          </van-cell-group>
        </van-skeleton>
      </div>
    </div>
    <!-- 自定义图标 -->
    <van-popup
      v-model:show="wifishow"
      closeable
      close-icon="close"
      position="bottom"
      :style="{ height: '35%' }"
    >
      <van-form class="wifishow-box" @submit="onConfirmSetWifi">
        <div class="wifishow-box-title">WIFI设置</div>
        <div class="wifishow-box-info">
          <van-cell-group inset>
          <van-field
            v-model="WIFItext"
            name="WIFItext"
            :rules="[{ required: true, message: '请输入正确内容'}]"
            label="WIFI名称"
            placeholder="请输入WIFI名称"
          />
          <van-field
            v-model="PSWtext"
            :rules="[{ required: true,  message: '请输入正确内容'}]"
            name="PSWtext"
            label="WIFI密码"
            placeholder="请输入WIFI密码"
          />
        </van-cell-group>
        </div>
        <div class="wifishow-box-btn"><van-button native-type="submit" type="primary" round style="width: 95%;">确认修改</van-button></div>
      </van-form>
  </van-popup>
    <!-- 自定义图标 -->
  <van-popup
      v-model:show="SetBlushow"
      closeable
      close-icon="close"
      position="bottom"
      :style="{ height: '30%' }"
    >
    <van-form class="wifishow-box" @submit="onConfirmBLU">
        <div class="wifishow-box-title">蓝牙名称设置</div>
        <div class="wifishow-box-info">
          <van-cell-group inset>
          <van-field
            v-model="BluetoothName"
            name="BluetoothName"
            :rules="[{ required: true, message: '请输入正确内容'}]"
            label="蓝牙名称"
            placeholder="请输入蓝牙名称"
          />
        </van-cell-group>
        </div>
        <div class="wifishow-box-btn"><van-button native-type="submit" type="primary" round style="width: 95%;">确认修改</van-button></div>
      </van-form>
  </van-popup>
  </div>
</template>

<script>

import { showLoadingToast ,closeToast} from 'vant';
import { showNotify } from 'vant';
import { showToast } from 'vant';
import { ref, onMounted } from 'vue';
export default {
  name: 'Bluetooth',
  data(){
    return {
      loading: true,
      wifishow: false,
      SetBlushow: false,
      ADVBluetooth: undefined,
      BluetoothInfo: "",
      BluetoothStatus: false,
      BluetoothName: "",
      imgBlu: require('../assets/蓝牙.png'),
      content: '未连接',
      color: '#d9352f',
      WIFItext: "jx_office_2.4G",
      PSWtext: "advchip12345678",
      BLUtext: "",
      facilityInfo: {
        facilityIP: "",
        facilityTrait: '0000ff01-0000-1000-8000-00805f9b34fb',
        facilityService: '000000ff-0000-1000-8000-00805f9b34fb'
      }
    }
  },
  components: {
  },
  methods:{
    onBluetooth(){
      const toast = showLoadingToast({
        duration: 0,
        message: "搜索蓝牙设备中...",
        forbidClick: true,
      });
      navigator.bluetooth.requestDevice({
            acceptAllDevices: true,
            optionalServices: ['000000ff-0000-1000-8000-00805f9b34fb']
        })
        .then(device => {
          console.log('设备已找到，连接中...', device);
          this.BluetoothName = device.name
          toast.message = `设备已找到，连接中...`;
          return device.gatt.connect();
        },(err) => {
            closeToast();
        })
        .then(server => {
          console.log('设备已连接，发现服务中...', server);
          toast.message = `设备已连接，发现服务中...`;
          return server.getPrimaryService('000000ff-0000-1000-8000-00805f9b34fb');
        }).then(service => {
          console.log('服务已发现，发现特征中...', service);
          toast.message = `服务已发现，发现特征中...`;
          return service.getCharacteristics();
        }).then(characteristics => {
          console.log('特征已发现，准备读写中...', characteristics);
          toast.message = `特征已发现，准备读写中...`;
          this.ADVBluetooth = characteristics;
          characteristics.forEach(characteristic => {
            setTimeout(() => {
              if (characteristic.properties.write && characteristic.uuid === "0000ff01-0000-1000-8000-00805f9b34fb") {
                const encoder = new TextEncoder();
                const data = encoder.encode(`2 ${this.BluetoothName} `);
                characteristic.writeValue(data).then(()=>{
                  closeToast();
                  // 成功通知
                  showNotify({ type: 'success', message: `连接${this.BluetoothName}成功` });
                  this.imgBlu = require('../assets/蓝牙蓝.png')
                  this.content= '连接中'
                  this.BluetoothStatus = true
                  this.color= '#58be6a'
                },(err)=>{
                  console.log("连接报错");
                  this.BluetoothName = "";
                  closeToast();
                  showNotify({ type: 'danger', message: `连接蓝牙设备失败请重新尝试` });
                });
              }
            }, 1000);
            if (characteristic.properties.notify || characteristic.properties.indicate) {
                characteristic.startNotifications();
                //监听蓝牙返回事件
                characteristic.addEventListener('characteristicvaluechanged',this.monitorData);
              }
          });
        }).catch(error => {
          console.error(`Error: ${error}`);
        });
    
    },
    monitorData(event){
      const hexArr = Array.prototype.map.call(
        new Uint8Array(event.target.value.buffer),bit=>bit);
      console.log(hexArr);
      this.loading = false;
      this.facilityInfo.facilityIP = `${hexArr[0]}.${hexArr[1]}.${hexArr[2]}.${hexArr[3]}`
    },
    onSetWifi(){
      if(this.BluetoothStatus){
        this.wifishow = true
      }else{
        showToast('未连接设备')
      }

    },
    onSetBlu(){
      if(this.BluetoothStatus){
        this.SetBlushow = true
      }else{
        showToast('未连接设备')
      }

    },
    onConfirmSetWifi(){
      console.log("wifi",this.WIFItext,this.PSWtext);
      this.ADVBluetooth.forEach(characteristic => {
        if (characteristic.properties.write && characteristic.uuid === "0000ff01-0000-1000-8000-00805f9b34fb") {
          const encoder = new TextEncoder();
          const data = encoder.encode(`1 ${this.WIFItext} ${this.PSWtext} `);
          characteristic.writeValue(data).then(()=>{
            // 成功通知
            showNotify({ type: 'success', message: 'WIFI配置成功' });
            let i=3;
            setInterval(() => {
              if(i==0){
                closeToast();
                location.reload();    // 重新加载当前页面
              }else{
                i--
              }
            }, 1000);
            showLoadingToast({
                duration: 0,
                message:  `${i}秒后刷新页面请重新连接...`,
                forbidClick: true,
              });
            console.log('WIFI发送Data sent successfully!');
          },(err)=>{
            console.log("连接报错");
            showNotify({ type: 'danger', message: `蓝牙断开请新连接` });
            this.BluetoothStatus=false;
            this.imgBlu = require('../assets/蓝牙.png')
            this.content= '未连接'
            this.color= '#d9352f'
          });
          this.wifishow = false
        }
      })
 
    },
    onConfirmBLU(){
      console.log("蓝牙",this.BluetoothName);
      this.ADVBluetooth.forEach(characteristic => {
        if (characteristic.properties.write && characteristic.uuid === "0000ff01-0000-1000-8000-00805f9b34fb") {
          const encoder = new TextEncoder();
          const data = encoder.encode(`2 ${this.BluetoothName} `);
          characteristic.writeValue(data).then(()=>{
            console.log('蓝牙发送Data sent successfully!');    
            // 成功通知
            showNotify({ type: 'success', message: '蓝牙改名成功' });
            let i=3;
            setInterval(() => {
              if(i==0){
                closeToast();
                location.reload();    // 重新加载当前页面
              }else{
                i--
              }
            }, 1000);
            showLoadingToast({
                duration: 0,
                message:  `${i}秒后刷新页面请重新连接...`,
                forbidClick: true,
              });
            this.SetBlushow = false;
            //再发一次wifi确保改名启动成功
            setTimeout(() => {
            this.ADVBluetooth.forEach(characteristic => {
              if (characteristic.properties.write && characteristic.uuid === "0000ff01-0000-1000-8000-00805f9b34fb") {
                const encoder = new TextEncoder();
                const data = encoder.encode(`1 TMD 1122334455 `);
                characteristic.writeValue(data);
                console.log('WIFI发送Data sent successfully!');
                // document.getElementById('box').innerHTML += `<p>Data sent successfully!1</p>`;
                }
              })
            }, 1000);
          },(err)=>{
            showNotify({ type: 'danger', message: `蓝牙断开请新连接` });
            this.BluetoothStatus=false;
            this.imgBlu = require('../assets/蓝牙.png')
            this.content= '未连接'
            this.color= '#d9352f'
          });
        }
      })
    }
  }
}
</script>

<style>
.blu-box{
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background: #dfeaf0;
}
.badge-icon {
  display: block;
  font-size: 10px;
  line-height: 16px;
}
.disabled-element {

  opacity: 0.5; /* 通常还会降低透明度来表明该元素不可交互 */
}
.child {
    width: 3rem;
    height: 3rem;
    border-radius: 10px;
    background: #f2f3f5 ;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.child span{
  color: #757575;
  font-weight: 900;
}

.blu-info-box{
  width: 100%;
  flex: 0.4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.blu-text-box{
  width: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    
}
.blu-info-item-box{
  width: 80%;
  display: flex;
  justify-content: space-around;
  background: white;
  border-radius: 5px;
  padding: 0.3rem;

}
.blu-info-text-box-item{
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.bor{
  border-right: 2px solid #8a8a8a;
}
.blu-info-text-box-item span{
  font-size: 0.32rem;
  font-weight: 900;
}

.blu-info-text-box{
  width: 80%;
  border-radius: 5px;
  padding: 0.3rem;
  flex: 0.9;
  background: white;
}
.blu-info-text-box-item-box{
  width: 50%;
  padding: 0.2rem;
  border-radius: 0.3rem;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.blu-info-text-box-item-box:hover{
  background: #f2f2fd;
}


.wifishow-box{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.wifishow-box-title{
  width: 100%;
  flex: 0.2;
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.45rem;
}
.wifishow-box-info{
  width: 100%;
  flex: 0.5;

}
.wifishow-box-btn{
  width: 100%;
  flex: 0.2;
  display: flex;
  justify-content: center;
  align-items: center;
}
.blu-info-text-box-title{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.5rem;
  font-weight: 900;
  margin-bottom: 0.3rem;
}

.van-field__label{
  flex: 0.35 !important;
}
</style>
