<template>
    <Bluetooth/>
    <van-popup v-model:show="show" :style="{ padding: '64px' }" :close-on-click-overlay="false">
      <p>尚不支持此浏览器请下载</p>
      <p>为避免造成您的经济损失 请避免在ios系统使用</p>
      <p>鸿蒙或者安卓系统 请下载"谷歌浏览器"或者"Edge浏览器"</p>
    </van-popup>
</template>

<script>
import Bluetooth from './components/Bluetooth.vue';
export default {
  name: 'App',
  data(){
    return{
      show:true
    }
  },
  components: {
    Bluetooth
  },
  created(){
    this.show = !navigator.bluetooth
  }
}
</script>

<style>
:root {
 
}
</style>
