import { createApp } from 'vue'
import App from './App.vue'
import 'lib-flexible/flexible.js'
// 2. 引入组件样式
import 'vant/lib/index.css';
import { Button } from 'vant';
import { Badge } from 'vant';
import { Image as VanImage } from 'vant';
import { Notify } from 'vant';
import { Icon } from 'vant';
import { Popup } from 'vant';
import { Form, Field, CellGroup } from 'vant';
import { Toast } from 'vant';
import { Skeleton } from 'vant';

const app = createApp(App);


app.use(Button);
app.use(Badge);
app.use(VanImage);
app.use(Notify);
app.use(Icon);
app.use(Popup);
app.use(Field);
app.use(CellGroup);
app.use(Form);
app.use(Toast);
app.use(Skeleton);


app.mount("#app")
// 3. 注册你需要的组件
